import Vue from "vue";
import Vuex from "vuex";
import Setting from "./Setting/index";
Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  modules: {
    Setting,
  },
  state: {
    // authenticated: false,
  },
  mutations: {
    // setAuthentication(state, status) {
    //   state.authenticated = status;
    // },
  },
  actions: {},
  getters: {},
  strict: debug,
});
