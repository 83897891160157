<template>
<div>
  <button @click="translateFun" class='translateBtn'>translate</button>
  <span v-if='show'><Translator/></span>
  
  <router-view/>
  
</div>
</template>
<script>
import { socialvue } from './config/pluginInit'
import Translator from "vue-gtranslate";
// import Echo from "laravel-echo";
export default {
  name: 'App',
  components: {Translator
  },
  data(){
    return{
      show : false
    }
  },
  mounted () {
    socialvue.mainIndex()
  },
  methods : {
    translateFun(){
      this.show = !this.show
    }
  }

}
</script>
<style lang="scss">
  @import "assets/scss/style.scss";   



    .vg-body{
        display: grid;
        grid-template-columns: auto auto auto;
        padding: 10px;
        background: lightgrey;
        position :fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index:999;
        overflow-y: scroll; height:400px;
    }

    .vg-container{
        padding: 20px;
        font-size: 30px;
        text-align: center;
        

    }
    .vg-text{
        color: black;
        padding-left:10px
    }

    .vg-items{
        display:flex;
        align-items:center;

    }

    .vg-flag{
        height:40px;
    }
    .translateBtn{
      position: fixed;
      bottom:20px;
      right:20px;
      z-index: 9999;
    }


</style>
