import Vue from "vue";
import VueRouter from "vue-router";
/* Layouts */
const Layout = () => import("../layouts/Layout");
const Layout2 = () => import("../layouts/Layout2");
const Layout3 = () => import("../layouts/Layout3");
const Default = () => import("../layouts/BlankLayout");
const AuthLayout = () => import("../layouts/AuthLayouts/AuthLayout");
const AuthLayout2 = () => import("../layouts/AuthLayouts/AuthLayout2");

/* all routes */
const SocialProfile = () => import("../views/SocailMain/Profile/Profile");
const NewsEvent = () => import("../views/SocailMain/Profile/NewsEvent");
const SingleNews = () => import("../views/SocailMain/Profile/SingleNews");
const Birthday = () => import("../views/SocailMain/Bithdate/Bithdate");
const Music = () => import("../views/SocailMain/Music/Music");
const Picture = () => import("../views/SocailMain/Profile/PictureAlbum");
const Notification = () =>
  import("../views/SocailMain/Notifications/Notification");

/* Authentic View */
const SignIn1 = () => import("../views/AuthPages/Default/SignIn1");
const SignProfileDetail = () =>
  import("../views/SocailMain/Profile/SignProfileDetail");
const ProfileImage = () =>
    import("../views/SocailMain/Profile/ProfileImage.vue");
const Home = () => import("../views/SocailMain/Profile/Home");
const WorldClock = () => import("../views/SocailMain/Profile/WorldClock");
const Tips = () => import("../views/SocailMain/Profile/Tips");
const RecoverPassword1 = () =>
  import("../views/AuthPages/Default/RecoverPassword1");
const ConfirmMail1 = () => import("../views/AuthPages/Default/ConfirmMail1");
const ResetPassword = () => import("../views/AuthPages/Default/ResetPassword");
/* Extra Pages */

const Team = () => import("../views/Pages/Team");
const OrgChart = () => import("../views/Pages/OrgChart");
const Policies = () => import("../views/Pages/Policies");
const Admincp = () => import("../views/Pages/Admincp");
/* Apps Views */
const Calendar = () => import("../views/Apps/Calendar/Calendar");

/* User View */
const Profile = () => import("../views/User/Profile");
const ProfileEdit = () => import("../views/User/ProfileEdit");
const SocialApp = () => import("../views/Apps/Social/SocialApp");
/* Todo */
const ChatIndex = () => import("../views/Apps/Chat/Index");

Vue.use(VueRouter);
const homeRoutes = (prop, mode) => [
  {
    path: "home",
    name: prop + ".home",
    meta: {
      auth: true,
      name: "home",
      img: require("../assets/images/login/login-banner.jpg"),
    },
    component: Home,
  },
]

const beforeSignInRoutes = (prop, mode) => [
  {
    path: "sign-profile-detail",
    name: "sign-profile-detail",
    meta: {
      auth: true,
      name: "sign-profile-detail",
      img: require("../assets/images/login/form-banner.svg"),
    },
    component: SignProfileDetail,
  },
];

const childRoutes = (prop, mode) => [
  {
    path: "/",
    name: prop + ".list",
    meta: { auth: true, name: "Social App" },
    component: SocialApp,
  },
  {
    path: "/profile",
    name: prop + ".profile",
    meta: { auth: true, name: "Profile" },
    component: SocialProfile,
  },
  // {
  //   path: "sign-profile-detail",
  //   name: prop + ".sign-profile-detail",
  //   meta: {
  //     auth: true,
  //     name: "sign-profile-detail",
  //     img: require("../assets/images/login/form-banner.svg"),
  //   },
  //   component: SignProfileDetail,

  // },
  {
    path: "profile-image",
    name: prop + ".profile-image",
    meta: {
      auth: true,
      name: "profile-image",
      img: require("../assets/images/login/form-banner.svg"),
    },
    component: ProfileImage,
  },
  // {
  //   path: "home",
  //   name: prop + ".home",
  //   meta: {
  //     auth: true,
  //     name: "home",
  //     img: require("../assets/images/login/login-banner.jpg"),
  //   },
  //   component: Home,
  // },
  {
    path: "world-clock",
    name: prop + ".world-clock",
    meta: { auth: true, name: "world-clock" },
    component: WorldClock,
  },
  {
    path: "tips",
    name: prop + ".tips",
    meta: { auth: true, name: "Tips" },
    component: Tips,
  },
  {
    path: "news-event",
    name: prop + ".news-event",
    meta: {
      auth: true,
      name: "News & Event",
      img: require("../assets/images/page-img/profile-bg6.jpg"),
    },
    component: NewsEvent,
  },
  {
    path: "event/:id",
    name: prop + ".event",
    meta: {
      auth: true,
      name: "Event",
      img: require("../assets/images/page-img/profile-bg6.jpg"),
    },
    component: SingleNews,
  },
  {
    path: "notification",
    name: prop + ".notification",
    meta: { auth: true, name: "Notification" },
    component: Notification,
  },
  {
    path: "birthday",
    name: prop + ".birthday",
    meta: {
      auth: true,
      name: "Birthday",
      img: require("../assets/images/common-banner.jpg"),
    },
    component: Birthday,
  },
  {
    path: "music",
    name: prop + ".music",
    meta: {
      auth: true,
      name: "Music",
      img: require("../assets/images/page-img/profile-bg8.jpg"),
    },
    component: Music,
  },
  {
    path: "picture",
    name: prop + ".picture",
    meta: {
      auth: true,
      name: "Picture",
      img: require("../assets/images/page-img/profile-bg2.jpg"),
    },
    component: Picture,
  },
];
const coreChildRoute = (prop, mode) => [];
const formChildRoute = (prop, mode = false) => [];

const tableChildRoute = (prop, mode = false) => [];

const iconChildRoute = (prop, mode = false) => [];

const appChildRoute = (prop, mode = false) => [
  {
    path: "calendar",
    name: prop + ".calendar",
    meta: {
      auth: true,
      name: "Calendar",
      img: require("../assets/images/common-banner.jpg"),
    },
    component: Calendar,
  },
  {
    path: "chat",
    name: prop + ".chat",
    meta: { auth: true, name: "Chat" },
    component: ChatIndex,
  },
];

const authChildRoutes = (prop, mode = false) => [
  {
    path: "sign-in1",
    name: prop + ".sign-in1",
    meta: { auth: true },
    component: SignIn1,
    props: true,
  },
  // {
  //   path: "home",
  //   name: prop + ".home",
  //   meta: {
  //     auth: true,
  //     name: "home",
  //     img: require("../assets/images/login/login-banner.jpg"),
  //   },
  //   component: Home,
  // },
  {
    path: "password-reset1",
    name: prop + ".password-reset1",
    meta: { auth: true },
    component: RecoverPassword1,
    props: true,
  },
  {
    path: "confirm-mail1",
    name: prop + ".confirm-mail1",
    meta: { auth: true },
    component: ConfirmMail1,
    props: true,
  },
  {
    path: "reset-password/:token",
    name: prop + ".reset-password",
    meta: { auth: true },
    component: ResetPassword,
    props: true,
  },
];
const defaultlayout = (prop, mode = false) => [
  {
    path: "organization-chart",
    name: prop + ".organization-chart",
    meta: { auth: true, name: "OrgChart" },
    component: OrgChart,
  },
  {
    path: "team",
    name: prop + ".team",
    meta: { auth: true, name: "Team" },
    component: Team,
  },
  {
    path: "policies",
    name: prop + ".policies",
    meta: { auth: true, name: "Policies" },
    component: Policies,
  },
  {
    path: "admincp",
    name: prop + ".admincp",
    meta: { auth: true, name: "Admincp" },
    component: Admincp,
  },
];

const pagesChildRoutes = (prop, mode = false) => [];
const userChildRoute = (prop, mode = false) => [
  {
    path: "profile",
    name: prop + ".profile",
    meta: { auth: true, name: "Profile" },
    component: Profile,
  },
  {
    path: "profile-edit",
    name: prop + ".edit",
    meta: { auth: true, name: "Edit Profile" },
    component: ProfileEdit,
  },
];
const routes = [
  {
    path: "/",
    name: "social",
    component: Layout,
    children: childRoutes("social"),
  },
  {
    path: "/",
    name: "social",
    component: Layout3,
    children: beforeSignInRoutes("social"),
  },
  {
    path: "/core",
    name: "core",
    component: Layout,
    meta: { auth: true },
    children: coreChildRoute("core"),
  },
  {
    path: "/table",
    name: "table",
    component: Layout,
    meta: { auth: true },
    children: tableChildRoute("table"),
  },
  {
    path: "/icon",
    name: "icon",
    component: Layout,
    meta: { auth: true },
    children: iconChildRoute("icon"),
  },
  {
    path: "/auth",
    name: "auth1",
    component: AuthLayout,
    meta: { auth: true },
    children: authChildRoutes("auth1"),
  },
  {
    path: "/",
    name: "home",
    component: AuthLayout2,
    meta: { auth: true },
    children: homeRoutes("home"),
  },
  {
    path: "/auth",
    name: "auth2",
    component: AuthLayout2,
    meta: { auth: true },
    children: authChildRoutes("auth2"),
  },
  {
    path: "/pages",
    name: "pages",
    component: Default,
    meta: { auth: true },
    children: pagesChildRoutes("default"),
  },
  {
    path: "/",
    name: "extra-pages",
    component: Layout,
    meta: { auth: true },
    children: defaultlayout("extra-pages"),
  },
  {
    path: "/app",
    name: "app",
    component: Layout,
    meta: { auth: true },
    children: appChildRoute("app"),
  },
  {
    path: "/user",
    name: "user",
    component: Layout,
    meta: { auth: true },
    children: userChildRoute("user"),
  },
  {
    path: "/form",
    name: "form",
    component: Layout,
    meta: { auth: true },
    children: formChildRoute("form"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.VUE_APP_BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {

  const authenticatedUser = localStorage.getItem("token");
  // const requiresAuth = to.matched.some((record) => record.meta.auth);

  if (to.name !== "home.home" && !authenticatedUser) {
    if (to.name === "auth1.sign-in1") {
      next();
    }
    else if (to.name === "auth1.password-reset1") {
      next();
    }else if (to.name === "auth1.confirm-mail1") {
      next();
    } else if (to.name === "auth1.reset-password") {
      next();
    }else {
      next({
        name: "home.home",
      });
    }
  } else next();
    // if (to.name !== "home.home" && !authenticatedUser) {
    //   next({
    //     name: "home.home",
    //   });
    // }
    // else next();
  // }
  
  // Check for protected route
  // if (requiresAuth && !authenticatedUser) next({ name: "auth1.sign-in1" });
  // else next();
});

export default router;
