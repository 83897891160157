import Vue from "vue";
import "mutationobserver-shim";
import "./Utils/fliter";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Raphael from "raphael/raphael";
import "./plugins";
import "./registerServiceWorker";
import i18n from "./i18n";
import Vuex from "vuex";
// import Echo from "laravel-echo";
// window.Pusher = require("pusher-js");

global.Raphael = Raphael;

Vue.config.productionTip = false;

Vue.use(Vuex);

let vm = new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");

window.vm = vm;

// const token = localStorage.getItem("token");

// window.Echo = new Echo({
//   broadcaster: "pusher",
//   key: "12345",
//   wsHost: "localhost:8000",
//   authEndpoint: "http://localhost:8000/broadcasting/auth",
//   encrypted: true,
//   forceTLS: false,
//   wsPort: 6001,
//   wssPort: 6001,
//   disableStats: true,
//   enabledTransports: ["ws", "wss"],
//   auth: {
//     headers: {
//       authorization: "Bearer " + token,
//     },
//   },
// });
